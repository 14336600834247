import React from 'react';
import PropTypes from 'prop-types';
// import {navigate} from 'gatsby';
import Layout from '../../Layout/Layout';
import Spacer from '../../components/Spacer';
import CarDetails from '../../views/CarDetails';
import {useContentfulQuery} from '../../services/CustomHooks';
import {GET_CAR_DETAILS} from '../../util/graphQuery';
import Loading from '../../components/Loading';

const CarPage = ({params}) => {
  const {cid} = params;

  const {loading, error, data} = useContentfulQuery(GET_CAR_DETAILS, {
    variables: {cid},
  });

  const _renderContent = () => {
    if (error) {
      return <Spacer />;
    }
    if (loading) {
      return (
        <div className={'w-full h-72 flex justify-center items-center'}>
          <Loading />
        </div>
      );
    }
    return <CarDetails car={data.car} />;
  };

  return (
    <>
      <Spacer />
      {_renderContent()}
    </>
  );
};

export default CarPage;

CarPage.propTypes = {
  params: PropTypes.object,
};
CarPage.defaultProps = {
  params: {},
};
