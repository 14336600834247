import React, {useState, useEffect, useCallback} from 'react';
import _ from 'lodash';
import {navigate} from 'gatsby';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {BLOCKS} from '@contentful/rich-text-types';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import Text from '../components/Typograhpy/Text';
import ContentContainer from '../components/ContentContainer';
import Header from '../components/Typograhpy/Header';
import Hr from '../components/Hr';
import SpecItem from '../components/SpecItem';
import Button from '../components/Button';
import ContentfulImage from '../components/ContentfulImage';
import CarImageSection from '../components/CarImageSection';
import constants from '../constants';
import Utils from '../util/util';

const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, [text]) => {
      return <Text value={text} className={'mb-6'} />;
    },
  },
};

const CarDetails = ({car}) => {
  const {t} = useTranslation();

  /**
   * value === car property's name
   */
  const specsList = [
    {
      label: t('car-details.model'),
      value: 'model',
    },
    {
      label: t('car-details.year'),
      value: 'year',
    },
    {
      label: t('car-details.transmission'),
      value: 'transmission',
    },
    {
      label: t('car-details.color'),
      value: 'color',
    },
    {
      label: t('car-details.engine'),
      value: 'engine',
    },
    {
      label: t('car-details.performance'),
      value: 'performance',
    },
    {
      label: t('car-details.seat'),
      value: 'seat',
    },
    {
      label: t('car-details.interior'),
      value: 'interior',
    },
    {
      label: t('car-details.status'),
      value: 'status',
    },
  ];

  const getImageWidthForCover = useCallback((breakpoint) => {
    let returnValue;

    switch (breakpoint) {
      case '2xs':
        returnValue = Utils.breakpointSize.xs;
        break;
      case 'xs':
        returnValue = Utils.breakpointSize.sm;
        break;
      case 'sm':
        returnValue = Utils.breakpointSize.md;
        break;
      case 'md':
        returnValue = Utils.breakpointSize.lg;
        break;
      case 'lg':
        returnValue = Utils.breakpointSize.xl;
        break;
      case 'xl':
        returnValue = Utils.breakpointSize['2xl'];
        break;
      default:
        if (typeof window !== 'undefined') returnValue = window.innerWidth;
        break;
    }
    return returnValue;
  }, []);

  const images = _.get(car, 'imageCollection.items', []);

  const getCoverImageOptions = () => {
    let imageOptions = {
      fm: 'webp',
    };
    const w = getImageWidthForCover(Utils.getCurrentBreakpoint());
    if (w) {
      imageOptions = {
        ...imageOptions,
        w,
      };
    }
    return imageOptions;
  };

  // state for refetching to get better resolution of the image
  const [coverImageOptions, setcoverImageOptions] = useState(
    getCoverImageOptions(Utils.getCurrentBreakpoint())
  );

  const handleResize = _.debounce(
    () => {
      if (!window) return;
      const _options = getCoverImageOptions();
      // only change option if window become larger
      if (_options.w && window.innerWidth > coverImageOptions.w) {
        setcoverImageOptions((prevState) => ({
          ...prevState,
          ..._options,
        }));
      }
    },
    1000,
    {trailing: true}
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const _renderSpecs = () => {
    return _.map(specsList, (i) => {
      const value = _.get(car, i.value, null);
      if (!value) return null;
      return (
        <SpecItem
          key={`spec-${value}`}
          label={i.label}
          value={_.toUpper(value)}
          className={'w-1/2 lg:w-1/4 lg:px-3'}
        />
      );
    });
  };

  const _onClickBuy = () => {
    if (!_.get(car, 'status', false) === constants.CAR_STATUS.AVAILABLE) return;
    const message = `Interested car:\t${_.get(
      car,
      'title',
      ''
    )}\nModel:\t${_.get(car, 'model', '')}\nYear:\t${_.get(
      car,
      'year',
      ''
    )}\n\n`;
    navigate('/Contact', {
      state: {
        queryType: constants.QUERY_TYPE.CAR_PURCHASE,
        message,
      },
    });
  };

  const coverImageUrl = Utils.getCImageUrl(_.first(images));

  const handleOnClickCover = () => {
    if (!window) return;
    window.open(coverImageUrl, '_blank');
  };

  return (
    <>
      <div
        onClick={handleOnClickCover}
        onKeyPress={handleOnClickCover}
        role="button"
        tabIndex={0}
      >
        <ContentfulImage
          alt={'cover'}
          imageUrl={coverImageUrl}
          options={coverImageOptions}
          outterClassName={`w-full h-vh-60`}
          className={'cursor-pointer'}
        />
      </div>
      <CarImageSection images={_.slice(images, 1)} />
      <ContentContainer>
        <div className={'mb-12'}>
          <Header value={_.get(car, 'title', '')} className={'mb-6'} />
          {documentToReactComponents(car.body.json, richTextOptions)}
          <Hr />
          <div
            className={`mx-auto w-full lg:w-9/12 flex flex-wrap gap-y-8 pb-12`}
          >
            {_renderSpecs()}
          </div>
          <div className={'flex justify-center'}>
            <Button
              title={t('button.bring-it-home')}
              onClickBtn={_onClickBuy}
              disabled={car.status !== constants.CAR_STATUS.AVAILABLE}
            />
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default CarDetails;

CarDetails.propTypes = {
  cid: PropTypes.string,
};
CarDetails.defaultProps = {
  cid: '',
};
