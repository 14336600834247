import React, {useState, useEffect, useCallback} from 'react';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import CarImageItem from './CarImageItem';
import Button from '../Button';
import Utils from '../../util/util';

const getNumberToShowOnInit = () => {
  switch (Utils.getCurrentBreakpoint()) {
    case '2xs':
      return 2;
    case 'xs':
      return 2;
    case 'sm':
      return 2;
    case 'md':
      return 2;
    case 'lg':
      return 3;
    case 'xl':
      return 3;
    case '2xl':
      return 3;
    default:
      return 3;
  }
};

const getImageWidthByBreakpoint = () => {
  let _width;
  switch (Utils.getCurrentBreakpoint()) {
    case '2xs':
      _width = Utils.breakpointSize.xs / 2;
      break;
    case 'xs':
      _width = Utils.breakpointSize.sm / 2;
      break;
    case 'sm':
      _width = Utils.breakpointSize.md / 2;
      break;
    case 'md':
      _width = Utils.breakpointSize.lg / 3;
      break;
    case 'lg':
      _width = Utils.breakpointSize.lg / 3;
      break;
    case 'xl':
      _width = Utils.breakpointSize.xl / 3;
      break;
    default:
      _width = Utils.breakpointSize.xl / 3;
      break;
  }
  return Math.floor(_width);
};

const CarImageSection = ({images}) => {
  const {t} = useTranslation();
  const [open, setopen] = useState(false);
  const [numberToShowOnInit, setnumberToShowOnInit] = useState(
    getNumberToShowOnInit()
  );

  const handleResize = useCallback(() => {
    setnumberToShowOnInit(getNumberToShowOnInit());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const imagesForFirstRow = _.slice(images, 0, numberToShowOnInit);
  const hiddenImages = _.slice(images, numberToShowOnInit);

  const handleOnClickLoadMore = () => {
    setopen((prevState) => !prevState);
  };

  const getImageOptions = () => {
    let _options = {
      fm: 'webp',
    };
    const w = getImageWidthByBreakpoint(Utils.getCurrentBreakpoint());
    if (w) {
      _options = {
        ..._options,
        w,
      };
    }
    return _options;
  };

  const _renderFirstRow = () => {
    return _.map(imagesForFirstRow, (i, idx) => {
      return (
        <CarImageItem
          key={'f-car-image-' + idx}
          index={idx}
          image={i}
          alt={'f-car-image-' + idx}
          options={getImageOptions()}
        />
      );
    });
  };

  const _renderHidden = () => {
    return _.map(hiddenImages, (i, idx) => {
      return (
        <CarImageItem
          key={'car-image-' + idx}
          index={idx}
          image={i}
          alt={'car-image-' + idx}
          options={getImageOptions()}
        />
      );
    });
  };

  return (
    <motion.div className={`mx-auto w-full max-w-bp-xl`}>
      <motion.div className={`flex flex-wrap`}>
        {_renderFirstRow()}
        {open && _renderHidden()}
      </motion.div>
      <motion.div className={`w-full h-28 flex justify-center items-center`}>
        {_.size(images) > numberToShowOnInit && (
          <Button
            title={open ? t('button.load-less') : t('button.load-more')}
            onClickBtn={handleOnClickLoadMore}
            second
          />
        )}
      </motion.div>
    </motion.div>
  );
};

export default CarImageSection;

CarImageSection.propTypes = {
  images: PropTypes.array,
};
CarImageSection.defaultProps = {
  image: [],
};
