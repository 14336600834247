import React from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import ContentfulImage from '../ContentfulImage';
import Utils from '../../util/util';

const CarImageItem = ({image, index, alt, options}) => {
  const imageUrl = Utils.getCImageUrl(image);
  const handleOnClick = () => {
    if (!window) return;
    window.open(imageUrl, '_blank');
  };
  return (
    <div
      className={`h-40 md:h-64 w-1/2 lg:w-1/3`}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      role="button"
      tabIndex={0}
    >
      <ContentfulImage
        imageUrl={imageUrl}
        outterClassName={`h-full w-full`}
        alt={alt}
        options={options}
        className={'cursor-pointer'}
        whileHover={{
          scale: 1.1,
        }}
        whileFocus={{
          scale: 1.1,
        }}
        whileTap={{
          scale: 1.1,
        }}
      />
    </div>
  );
};

export default CarImageItem;

CarImageItem.propTypes = {
  image: PropTypes.object,
  index: PropTypes.number.isRequired,
  alt: PropTypes.string,
  options: PropTypes.object,
};
CarImageItem.defaultProps = {
  image: {},
  alt: 'car-image',
  options: {},
};
