import React from 'react';
import PropTypes from 'prop-types';

const Hr = ({className}) => {
  return (
    <hr
      className={`mx-auto my-12 w-10/12 bg-primary border-primary ${className}`}
    />
  );
};

export default Hr;

Hr.propTypes = {
  className: PropTypes.string,
};
Hr.defaultProps = {
  className: '',
};
